import _helper from "../helper";
import _ActionSet from "../ActionSet";
import _types from "../types";
var exports = {};
var __extends = exports && exports.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __assign = exports && exports.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ResourcePicker = exports.update = exports.close = exports.cancel = exports.open = exports.select = exports.ActionVerb = exports.ResourceType = exports.ProductStatus = exports.ProductVariantInventoryManagement = exports.ProductVariantInventoryPolicy = exports.WeightUnit = exports.FulfillmentServiceType = exports.CollectionSortOrder = exports.Action = void 0;
var helper_1 = _helper;
var ActionSet_1 = _ActionSet;
var types_1 = _types;
var Action;
(function (Action) {
  Action["OPEN"] = "APP::RESOURCE_PICKER::OPEN";
  Action["SELECT"] = "APP::RESOURCE_PICKER::SELECT";
  // Deprecated in 0.5.0 use 'APP::RESOURCE_PICKER::CANCEL' instead
  Action["CLOSE"] = "APP::RESOURCE_PICKER::CLOSE";
  Action["UPDATE"] = "APP::RESOURCE_PICKER::UPDATE";
  Action["CANCEL"] = "APP::RESOURCE_PICKER::CANCEL";
})(Action = exports.Action || (exports.Action = {}));
var CollectionSortOrder;
(function (CollectionSortOrder) {
  CollectionSortOrder["Manual"] = "MANUAL";
  CollectionSortOrder["BestSelling"] = "BEST_SELLING";
  CollectionSortOrder["AlphaAsc"] = "ALPHA_ASC";
  CollectionSortOrder["AlphaDesc"] = "ALPHA_DESC";
  CollectionSortOrder["PriceDesc"] = "PRICE_DESC";
  CollectionSortOrder["PriceAsc"] = "PRICE_ASC";
  CollectionSortOrder["CreatedDesc"] = "CREATED_DESC";
  CollectionSortOrder["Created"] = "CREATED";
  CollectionSortOrder["MostRelevant"] = "MOST_RELEVANT";
})(CollectionSortOrder = exports.CollectionSortOrder || (exports.CollectionSortOrder = {}));
var FulfillmentServiceType;
(function (FulfillmentServiceType) {
  FulfillmentServiceType["GiftCard"] = "GIFT_CARD";
  FulfillmentServiceType["Manual"] = "MANUAL";
  FulfillmentServiceType["ThirdParty"] = "THIRD_PARTY";
})(FulfillmentServiceType = exports.FulfillmentServiceType || (exports.FulfillmentServiceType = {}));
var WeightUnit;
(function (WeightUnit) {
  WeightUnit["Kilograms"] = "KILOGRAMS";
  WeightUnit["Grams"] = "GRAMS";
  WeightUnit["Pounds"] = "POUNDS";
  WeightUnit["Ounces"] = "OUNCES";
})(WeightUnit = exports.WeightUnit || (exports.WeightUnit = {}));
var ProductVariantInventoryPolicy;
(function (ProductVariantInventoryPolicy) {
  ProductVariantInventoryPolicy["Deny"] = "DENY";
  ProductVariantInventoryPolicy["Continue"] = "CONTINUE";
})(ProductVariantInventoryPolicy = exports.ProductVariantInventoryPolicy || (exports.ProductVariantInventoryPolicy = {}));
var ProductVariantInventoryManagement;
(function (ProductVariantInventoryManagement) {
  ProductVariantInventoryManagement["Shopify"] = "SHOPIFY";
  ProductVariantInventoryManagement["NotManaged"] = "NOT_MANAGED";
  ProductVariantInventoryManagement["FulfillmentService"] = "FULFILLMENT_SERVICE";
})(ProductVariantInventoryManagement = exports.ProductVariantInventoryManagement || (exports.ProductVariantInventoryManagement = {}));
var ProductStatus;
(function (ProductStatus) {
  ProductStatus["Active"] = "ACTIVE";
  ProductStatus["Archived"] = "ARCHIVED";
  ProductStatus["Draft"] = "DRAFT";
})(ProductStatus = exports.ProductStatus || (exports.ProductStatus = {}));
var ResourceType;
(function (ResourceType) {
  ResourceType["Product"] = "product";
  ResourceType["ProductVariant"] = "variant";
  ResourceType["Collection"] = "collection";
})(ResourceType = exports.ResourceType || (exports.ResourceType = {}));
var ActionVerb;
(function (ActionVerb) {
  ActionVerb["Add"] = "add";
  ActionVerb["Select"] = "select";
})(ActionVerb = exports.ActionVerb || (exports.ActionVerb = {}));
function select(payload) {
  return helper_1.actionWrapper({
    payload: payload,
    group: types_1.Group.ResourcePicker,
    type: Action.SELECT
  });
}
exports.select = select;
function open(payload) {
  return helper_1.actionWrapper({
    payload: payload,
    group: types_1.Group.ResourcePicker,
    type: Action.OPEN
  });
}
exports.open = open;
function cancel(payload) {
  return helper_1.actionWrapper({
    payload: payload,
    group: types_1.Group.ResourcePicker,
    type: Action.CANCEL
  });
}
exports.cancel = cancel;
function close(payload) {
  return helper_1.actionWrapper({
    payload: payload,
    group: types_1.Group.ResourcePicker,
    type: Action.CANCEL
  });
}
exports.close = close;
function update(payload) {
  return helper_1.actionWrapper({
    payload: payload,
    group: types_1.Group.ResourcePicker,
    type: Action.UPDATE
  });
}
exports.update = update;
var ResourcePicker = /** @class */function (_super) {
  __extends(ResourcePicker, _super);
  function ResourcePicker(app, options, resourceType) {
    var _this = _super.call(this, app, types_1.Group.ResourcePicker, types_1.Group.ResourcePicker) || this;
    _this.initialSelectionIds = [];
    _this.selection = [];
    _this.resourceType = resourceType;
    _this.set(options, false);
    return _this;
  }
  Object.defineProperty(ResourcePicker.prototype, "payload", {
    get: function () {
      return __assign(__assign({}, this.options), {
        id: this.id,
        resourceType: this.resourceType
      });
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(ResourcePicker.prototype, "options", {
    get: function () {
      var options = {
        initialQuery: this.initialQuery,
        filterQuery: this.filterQuery,
        selectMultiple: this.selectMultiple,
        initialSelectionIds: this.initialSelectionIds,
        showHidden: this.showHidden,
        actionVerb: this.actionVerb
      };
      if (this.resourceType === ResourceType.Product) {
        var productOptions = __assign(__assign({}, options), {
          showVariants: this.showVariants,
          showDraft: this.showDraft,
          showArchived: this.showArchived,
          showDraftBadge: this.showDraftBadge,
          showArchivedBadge: this.showArchivedBadge
        });
        return productOptions;
      }
      return options;
    },
    enumerable: false,
    configurable: true
  });
  ResourcePicker.prototype.set = function (options, shouldUpdate) {
    if (shouldUpdate === void 0) {
      shouldUpdate = true;
    }
    var mergedOptions = helper_1.getMergedProps(this.options, options);
    var initialQuery = mergedOptions.initialQuery,
      filterQuery = mergedOptions.filterQuery,
      _a = mergedOptions.initialSelectionIds,
      initialSelectionIds = _a === void 0 ? [] : _a,
      _b = mergedOptions.showHidden,
      showHidden = _b === void 0 ? true : _b,
      _c = mergedOptions.showVariants,
      showVariants = _c === void 0 ? true : _c,
      _d = mergedOptions.showDraft,
      showDraft = _d === void 0 ? true : _d,
      _e = mergedOptions.showArchived,
      showArchived = _e === void 0 ? true : _e,
      _f = mergedOptions.showDraftBadge,
      showDraftBadge = _f === void 0 ? false : _f,
      _g = mergedOptions.showArchivedBadge,
      showArchivedBadge = _g === void 0 ? false : _g,
      _h = mergedOptions.selectMultiple,
      selectMultiple = _h === void 0 ? true : _h,
      _j = mergedOptions.actionVerb,
      actionVerb = _j === void 0 ? ActionVerb.Add : _j;
    this.initialQuery = initialQuery;
    this.filterQuery = filterQuery;
    this.initialSelectionIds = initialSelectionIds;
    this.showHidden = showHidden;
    this.showVariants = showVariants;
    this.showDraft = showDraft;
    this.showArchived = showArchived;
    this.showDraftBadge = showDraftBadge;
    this.showArchivedBadge = showArchivedBadge;
    this.selectMultiple = selectMultiple;
    this.actionVerb = actionVerb;
    if (shouldUpdate) {
      this.update();
    }
    return this;
  };
  ResourcePicker.prototype.dispatch = function (action, selection) {
    if (action === Action.OPEN) {
      this.open();
    } else if (action === Action.UPDATE) {
      this.update();
    } else if (action === Action.CLOSE || action === Action.CANCEL) {
      this.cancel();
    } else if (action === Action.SELECT) {
      this.selection = selection;
      this.app.dispatch(select({
        id: this.id,
        selection: this.selection
      }));
    }
    return this;
  };
  ResourcePicker.prototype.update = function () {
    this.app.dispatch(update(this.payload));
  };
  ResourcePicker.prototype.open = function () {
    this.app.dispatch(open(this.payload));
  };
  ResourcePicker.prototype.cancel = function () {
    this.app.dispatch(cancel({
      id: this.id
    }));
  };
  ResourcePicker.prototype.close = function () {
    this.cancel();
  };
  return ResourcePicker;
}(ActionSet_1.ActionSet);
exports.ResourcePicker = ResourcePicker;
export default exports;
export const __esModule = exports.__esModule;
const _ResourcePicker = exports.ResourcePicker,
  _update = exports.update,
  _close = exports.close,
  _cancel = exports.cancel,
  _open = exports.open,
  _select = exports.select,
  _ActionVerb = exports.ActionVerb,
  _ResourceType = exports.ResourceType,
  _ProductStatus = exports.ProductStatus,
  _ProductVariantInventoryManagement = exports.ProductVariantInventoryManagement,
  _ProductVariantInventoryPolicy = exports.ProductVariantInventoryPolicy,
  _WeightUnit = exports.WeightUnit,
  _FulfillmentServiceType = exports.FulfillmentServiceType,
  _CollectionSortOrder = exports.CollectionSortOrder,
  _Action = exports.Action;
export { _ResourcePicker as ResourcePicker, _update as update, _close as close, _cancel as cancel, _open as open, _select as select, _ActionVerb as ActionVerb, _ResourceType as ResourceType, _ProductStatus as ProductStatus, _ProductVariantInventoryManagement as ProductVariantInventoryManagement, _ProductVariantInventoryPolicy as ProductVariantInventoryPolicy, _WeightUnit as WeightUnit, _FulfillmentServiceType as FulfillmentServiceType, _CollectionSortOrder as CollectionSortOrder, _Action as Action };